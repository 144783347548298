
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'tab-skeleton',
  props: {
    loading: {
      type: Boolean,
      default: true,
    },
    width: {
      type: String,
      default: '240px',
    },
    height: {
      type: String,
      default: '240px',
    },
  },
});
