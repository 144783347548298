import { resolveComponent as _resolveComponent, normalizeStyle as _normalizeStyle, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { style: {"padding":"14px"} }
const _hoisted_2 = { style: {"display":"flex","align-items":"center","justify-items":"space-between","margin-top":"16px","height":"16px"} }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_skeleton_item = _resolveComponent("el-skeleton-item")!
  const _component_el_skeleton = _resolveComponent("el-skeleton")!

  return (_openBlock(), _createBlock(_component_el_skeleton, {
    style: _normalizeStyle({
      width: _ctx.width,
    }),
    loading: _ctx.loading,
    animated: ""
  }, {
    template: _withCtx(() => [
      _createVNode(_component_el_skeleton_item, {
        variant: "rect",
        style: _normalizeStyle({
          width: _ctx.width,
          height: _ctx.height,
        })
      }, null, 8, ["style"]),
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_el_skeleton_item, {
          variant: "h3",
          style: {"width":"50%"}
        }),
        _createElementVNode("div", _hoisted_2, [
          _createVNode(_component_el_skeleton_item, {
            variant: "text",
            style: {"margin-right":"16px"}
          }),
          _createVNode(_component_el_skeleton_item, {
            variant: "text",
            style: {"width":"30%"}
          })
        ])
      ])
    ]),
    _: 1
  }, 8, ["style", "loading"]))
}