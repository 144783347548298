
import { defineComponent } from 'vue';
import { Getters as AuthGetters } from '@/store/enums/AuthEnums';
import { Getters as PlanGetters } from '@/store/enums/PlanEnums';

import {
  Actions as PaymentActions,
  Getters as PaymentGetters,
} from '@/store/enums/PaymentEnums';
import { mapGetters, mapActions } from 'vuex';
import _ from 'lodash';
import { numberFormat } from '@/utils/text';
import toasts from '@/utils/toasts';
import { DrawBundle } from '@/models/DrawBundleModel';
import { loaderEnabled } from '@/core/helpers/config';

export default defineComponent({
  name: 'view-plan-detail-tab',
  props: {
    planCode: {
      type: String,
      default: '',
    },
    bundles: {
      type: Array,
      default: () => {
        return {} as unknown as Array<DrawBundle>;
      },
    },
  },
  data: () => ({
    current: 'month',
    selected: 'free',
    default: 'free',
    loadTable: false,
    loadStart: '',
    planType: 'monthly',
  }),
  async mounted() {
    this.loadTable = true;
    await this.bundles;

    setTimeout(() => {
      this.loadTable = false;
    }, 2000);
  },
  computed: {
    ...mapGetters({
      authUser: AuthGetters.GET_AUTH_USER,
      listPlans: PlanGetters.GET_PLANS,
      checkoutErrors: PaymentGetters.GET_ACTION_ERROR,
    }),

    sortedBundles() {
      let planBundles = [...this.bundles].filter(
        (bundle) => (bundle as DrawBundle)?.amount.toLowerCase() !== 'free'
      );

      if (this.planCode !== '') {
        planBundles = [...this.bundles].filter(
          (bundle) =>
            (bundle as DrawBundle)?.amount.toLowerCase() === this.planCode
        );
      }

      return planBundles.sort((a: any, b: any) => {
        return parseFloat(a.amount) - parseFloat(b.amount);
      }) as DrawBundle[];
    },
  },
  methods: {
    ...mapActions({
      checkoutBundle: PaymentActions.DO_CHECKOUT_BUNDLES,
    }),
    formatMoney(amount) {
      return numberFormat(amount, 2);
    },
    choosePlanType(type) {
      this.planType = type;
    },
    checkoutPlan(bundle: DrawBundle) {
      if (this.loadStart) return;
      this.loadStart = bundle.bundle_id as string;

      const payload = {
        bundle: bundle.bundle_id,
        draw: this.$route?.params?.draw_id,
        cancel: `draws/${this.$route.params.draw_id}`,
      };

      return this.checkoutBundle(payload)
        .then((data) => {
          window.location = data.checkout.url as unknown as Location;
        })
        .catch(() => {
          this.loadStart = '';
          const { errors, message } = this.checkoutErrors;
          toasts.error(errors, message);
        });
    },

    calculatePrice(price, discount) {
      if (discount !== '0' && discount !== null) {
        return parseFloat(price) * parseFloat(discount);
      }

      return parseFloat(price).toFixed(0);
    },
    cancel() {
      return this.$router.replace({ path: 'dashboard' });
    },
  },
  setup() {
    return {
      loaderEnabled,
    };
  },
});
