
import { defineComponent } from 'vue';
import { mapActions, mapGetters } from 'vuex';
import useBreakpoints from 'vue-next-breakpoints';
import { acronym } from '@/utils/text';
import {
  Getters as DrawGetters,
  Actions as DrawActions,
} from '@/store/enums/DrawEnums';
import { Actions as SubActions } from '@/store/enums/SubscriptionEnums';
import {
  Getters as BundleGetters,
  Actions as BundleActions,
} from '@/store/enums/PlanBundleEnums';

import PlanBundleTabsView from '@/components/tables/PlanBundleTabsView.vue';
import TabLoader from '@/components/TabLoader.vue';

import {
  // toolbarDisplay,
  loaderEnabled,
} from '@/core/helpers/config';

export default defineComponent({
  name: 'view-draw',
  components: {
    TabLoader,
    PlanBundleTabsView,
  },
  data: () => ({
    loading: false,
  }),
  async mounted() {
    this.loading = true;
    if (!this.draw?.id) {
      await this.fetchCurrentDraw();
    }

    await this.fetchBundles();

    this.loading = false;
  },
  computed: {
    ...mapGetters({
      draw: DrawGetters.GET_DRAW,
      bundles: BundleGetters.GET_PLAN_BUNDLES,
    }),
    breakpoint() {
      return useBreakpoints({
        mobile: 800,
        desktop: [801],
      });
    },
    isMobile() {
      return (this.breakpoint as any)?.mobile?.matches;
    },
  },

  methods: {
    ...mapActions({
      fetchDraw: DrawActions.FETCH_DRAW,
      fetchDrawBundles: BundleActions.FETCH_PLAN_BUNDLES,
      fetchSubscriptions: SubActions.FETCH_ALL_SUBSCRIPTIONS,
    }),

    getRole(role) {
      return role == 'principal' ? 'Principal' : 'Property Manager';
    },
    getAcronym(name = '') {
      return acronym(name);
    },
    async fetchBundles() {
      const params = {
        drawId: this.$route.params.draw_id,
      };

      await this.fetchDrawBundles(params);
    },

    async fetchCurrentDraw() {
      const id = await this.$route.params.draw_id;

      await this.fetchDraw(id);
    },
  },
  watch: {
    draw: {
      handler(value) {
        this.loading = true;
      },
      deep: true,
    },
  },
  setup() {
    return {
      loaderEnabled,
    };
  },
});
